const store = {
  timeClocks: [],
  pending: false,
  receivedAt: null
};

const reducer = {
  TIMER_GET_MY: (state, action) => {
    const { payload } = action;
    state.timeClocks = payload.timeClocks;
    state.pending = false;
    state.receivedAt = new Date();
    return state;
  },
  TIMER_SET_PENDING: state => {
    state.pending = true;
    return state;
  }
};
export default (state = store, action) => {
  if (reducer[action.type]) return reducer[action.type]({ ...state }, action);
  return { ...state };
};
