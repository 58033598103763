const store = {
  persons: [],
  pending: true,
  error: null,
  receivedAt: null
};

export default (state = store, action) => {
  let newState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case "PERSONS_LOAD":
      newState.persons = payload.persons;
      newState.pending = payload.pending;
      newState.error = payload.error;
      newState.receivedAt = new Date();
      return newState;
    case "PERSONS_PENDING":
      newState.pending = true;
      newState.error = null;
      return newState;
    case "PERSONS_ADD":
      newState.pending = true;
      newState.error = null;
      newState.receivedAt = null;
      return newState;
    case "PERSONS_DELETE":
      newState.persons = newState.persons.filter(
        person => person.id !== payload.id
      );
      return newState;
    default:
      return newState;
  }
};
