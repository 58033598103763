import { Art } from "@datenbanker/devcloud-client-lib";
import { setArtPending } from "../actions/Navigation";

export default {
  NAVIGATION_OPEN_ART: async function(store, next, action) {
    const oldStore = store.getState();
    const links = oldStore.navigation.links;
    action.payload = {
      pools: [],
      index: null,
      receivedAt: null
    };

    for (let i = 0; links.length > i; i++) {
      const link = links[i];
      if (link.id === "art-collections") {
        action.payload.index = i;

        if (!link.receivedAt || +new Date() - link.receivedAt > 60000) {
          next(setArtPending(i));
          try {
            const art = new Art();
            action.payload.pools = await art.pool.myAll();
            action.payload.receivedAt = new Date();
          } catch (error) {}
        }
        break;
      }
    }
    return next(action);
  }
};
