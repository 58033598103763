const store = {
  artists: [],
  pending: true,
  error: null,
  receivedAt: null
};

export default (state = store, action) => {
  let newState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case "ARTISTS_LOADED":
      newState.artists = payload.artists;
      newState.pending = false;
      newState.error = null;
      newState.receivedAt = new Date();
      return newState;
    case "ARTISTS_PENDING":
      newState.pending = true;
      newState.error = null;
      return newState;
    case "ARTISTS_ADD":
      newState.pending = true;
      newState.error = null;
      newState.receivedAt = null;
      return newState;
    case "ARTISTS_DELETE":
      newState.artists = newState.artists.filter(
        artists => artists.id !== payload.id
      );
      return newState;
    default:
      return newState;
  }
};
