import { Customer } from "@datenbanker/devcloud-client-lib";
import { reset } from "../actions/Person";
export default {
  PERSON_GET: async (store, next, action) => {
    const oldStore = store.getState().person;
    //clear if there are still old data types
    if (!oldStore.pending && oldStore.id !== action.payload.id)
      store.dispatch(reset());

    const customer = new Customer();
    try {
      const person = await customer.person.get(action.payload.id);
      const { types } = person;

      let birthDate = "";
      if (person.birthDate) {
        const birthDateClass = new Date(person.birthDate);
        birthDate =
          birthDateClass.getFullYear() +
          "-" +
          (birthDateClass.getMonth() + 1) +
          "-" +
          birthDateClass.getDate();
      }

      const values = {
        title: person.title || "",
        firstName: person.firstName || "",
        lastName: person.lastName || "",
        gender: person.gender || "",
        birthDate: birthDate || "",
        placeOfBirth: person.placeOfBirth || "",
        salutation: person.salutation || "",
        company: person.company || "",
        position: person.position || "",
        potential: person.potential || 0,
        quality: person.quality || 0,
        description: person.description || "",
        email: person.email || "",
        phone: person.phone || "",
        mobilePhone: person.mobilePhone || "",
        businessPhone: person.businessPhone || "",
        website: person.website || "",
        facebook: person.facebook || "",
        instagram: person.instagram || "",
        street: person.street || "",
        zip: person.zip || "",
        city: person.city || "",
        country: person.country || "",
        types: Object.keys(types).filter(key => types[key] !== null),
        employee: {
          position: person.types.employee
            ? person.types.employee.position || ""
            : "",
          hoursPerWeek: person.types.employee
            ? person.types.employee.hoursPerWeek || ""
            : ""
        }
      };

      action.payload.values = values;
      action.payload.errors = {};
      action.payload.pending = false;
      action.payload.unkownError = false;
    } catch (err) {
      action.payload.unkownError = true;
    }

    try {
      const historyRaw = await customer.person.history(action.payload.id);

      let elements = [];
      const elementsLength = historyRaw.events.length;
      elements = historyRaw.events.reduce((reducer, currentItem, index) => {
        let toReturn = [...reducer];
        const previouseItemYear =
          index > 0
            ? new Date(reducer[index - 1].creationDate).getFullYear()
            : false;
        const currentItemYear = new Date(
          currentItem.creationDate
        ).getFullYear();

        if (previouseItemYear && previouseItemYear !== currentItemYear) {
          toReturn.push({ $type: "yearFlag", year: previouseItemYear });
        }
        toReturn.push({ id: currentItem.requestId, ...currentItem });
        if (elementsLength - 1 === index) {
          toReturn.push({ $type: "yearFlag", year: currentItemYear });
        }
        return toReturn;
      }, []);

      action.payload.history = {
        pending: false,
        unkownError: false,
        elements: elements
      };
    } catch (err) {
      action.payload.history = {
        pending: false,
        unkownError: true,
        elements: []
      };
    }

    return next(action);
  },
  PERSON_TIMECLOCK_GET: async (store, next, action) => {
    const customer = new Customer();
    try {
      const events = await customer.person.getAllTaskClocks(action.payload.id);
      let elements = [];
      const elementsLength = events.length;
      elements = events.reduce((reducer, currentItem, index) => {
        let toReturn = [...reducer];
        const previouseItemYear =
          index > 0 ? new Date(reducer[index - 1].start).getFullYear() : false;
        const currentItemYear = new Date(currentItem.start).getFullYear();
        if (previouseItemYear && previouseItemYear !== currentItemYear) {
          toReturn.push({ $type: "yearFlag", year: previouseItemYear });
        }
        toReturn.push({ id: currentItem.requestId, ...currentItem });
        if (elementsLength - 1 === index) {
          toReturn.push({ $type: "yearFlag", year: currentItemYear });
        }
        return toReturn;
      }, []);
      action.payload.timeClock = {
        pending: false,
        unknownError: false,
        elements
      };
    } catch (err) {
      action.payload.timeClock = {
        pending: false,
        unknownError: true,
        elements: []
      };
    }
    return next(action);
  },
  PERSON_UPDATE: async (store, next, action) => {
    //create fields
    let set = {};
    const oldStore = store.getState().person;
    action.payload.values = { ...oldStore.values };
    action.payload.errors = {};
    Object.keys(action.payload.fields).forEach(field => {
      let value = action.payload.fields[field];
      let object, fieldName;
      if (field.includes(":")) {
        const helper = field.split(":");
        object = helper[0];
        fieldName = helper[1];
      }

      switch (field) {
        case "potential":
        case "quality":
          set[field] = value[0];
          break;
        case "types":
          set[field] = {};
          //get symetrical diffrence between old types and new types. Then delete or add the type.
          oldStore.values.types
            .filter(type => !value.includes(type))
            .concat(value.filter(type => !oldStore.values.types.includes(type)))
            .forEach(item => {
              set[field][item] = oldStore.values.types.includes(item)
                ? null
                : {};
            });
          break;
        case "birthDate":
          set[field] = +new Date(value);
          break;
        case "employee:position":
          set.types = { employee: {} };
          set.types.employee[fieldName] = value;
          break;
        case "employee:hoursPerWeek":
          value = parseInt(value);
          set.types = { employee: {} };
          set.types.employee[fieldName] = value;
          break;
        case "zip":
          set[field] = parseInt(value);
          break;
        default:
          set[field] = value;
      }
      if (typeof fieldName !== "undefined")
        action.payload.values[object][fieldName] = value;
      else action.payload.values[field] = value;
    });

    // create request
    const customer = new Customer();

    try {
      await customer.person.update(set, action.payload.id);
      Object.keys(set).forEach(item => {
        action.payload.errors[item] = "";
      });
    } catch (err) {
      action.payload.values = {};
      action.payload.errors = {};
      if (err.code === "validationError") {
        const convertErrorCode = code => {
          switch (code) {
            case "alreadyExists":
              return "Existiert bereits.";
            case "notSet":
              return "Ist ein Pflichtfeld.";
            case "notString":
              return "Enthält ungültige Zeichen.";
            case "notInt":
              return "Ist keine Zahl.";
            case "notEmail":
              return "Enthält keine gültige Email-Adresse.";
            case "notPhone":
              return "Enthält keine gültige Telefonnummer.";
            default:
              return "Unbekannter Fehler. Code: " + code;
          }
        };
        const { errors } = err;
        let errorHelper = { ...oldStore.errors };
        errors.forEach(error => {
          if (error.key.startsWith("types.")) {
            errorHelper[
              error.key
                .split(".")
                .splice(1)
                .join(":")
            ] = convertErrorCode(error.error);
          } else if (error.key.startsWith("types"))
            errorHelper.types = "Ein unbekannter Fehler ist aufgetreten.";
          else {
            errorHelper[error.key] = convertErrorCode(error.error);
          }
        });
        action.payload.errors = errorHelper;
      } else {
        action.payload.unkownError = true;
      }

      action.type = "PERSON_UPDATE_ERROR";
      return next(action);
    }
    return next(action);
  }
};
