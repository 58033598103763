const store = {
  values: {
    name: "",
    displayName: "",
    member: [],
    public: false,
    password: ""
  },
  errors: {
    name: "",
    displayName: "",
    members: [],
    public: false,
    password: ""
  },
  pending: true,
  error: null,
  receivedAt: null
};

const reducer = {
  ART_POOL_GET: (state, action) => {
    const { payload } = action;
    state.values = { ...state.values, ...payload.values };
    state.pending = false;
    state.receivedAt = new Date();
    return state;
  },
  ART_POOL_PENDING: (state, action) => {
    state.pending = true;
    return state;
  }
};

export default (state = store, action) => {
  if (reducer[action.type]) return reducer[action.type]({ ...state }, action);
  return { ...state };
};
