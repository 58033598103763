const store = {
  id: null,
  person: null,
  personPool: null,
  values: {
    username: "",
    email: "",
    emailVerified: 0,
    phone: "",
    phoneVerified: 0,
    challenges: [],
    roles: [],
    pools: [],
    disabled: false
  },
  errors: {
    username: "",
    email: "",
    emailVerified: "",
    phone: "",
    phoneVerified: "",
    challenges: "",
    pools: ""
  },
  notFound: false,
  pending: true,
  unkownError: false,
  activePending: false,
  disablePending: false,
  deletePending: false,
  groupsPending: false,
  config: {
    userGroups: {
      Basic: { value: "Basic", label: "Basic" },
      Person: { value: "Person", label: "Personen" },
      Artist: { value: "Artist", label: "Künstler" },
      Art: { value: "Art", label: "Kunst" },
      User: { value: "User", label: "User CRM" },
      "Time Clock": { value: "Time Clock", label: "Stech Uhr" }
    }
  },
  receivedAt: null
};

const reducer = {
  USER_GET: (state, action) => {
    const { values, pending, notFound, unkownError, pool } = action.payload;
    state.id = action.payload.id;
    state.person = action.payload.person;
    state.values = values;
    state.pending = pending;
    state.notFound = notFound;
    state.unkownError = unkownError;
    state.personPool = pool;
    return state;
  },
  USER_RESET: (state, action) => {
    return { ...store };
  },
  USER_DELETED: (state, action) => {
    return { ...store, notFound: true, pending: false };
  },
  USER_UPDATE: (state, action) => {
    const { values } = action.payload;
    state.values = { ...state.values, ...values };
    Object.keys(values).forEach(field => (state.errors[field] = ""));

    return {
      ...state,
      activePending: false,
      disablePending: false,
      deletePending: false,
      groupsPending: false
    };
  },
  USER_ERROR: (state, action) => {
    const { errors, notFound, unkownError } = action.payload;
    state.errors = { ...state.errors, ...errors };
    state.notFound = notFound;
    state.unkownError = unkownError;
    return state;
  },
  USER_SET_PENDING_TYPES: (state, action) => {
    state = { ...state, ...action.payload };
    return state;
  }
};
export default (state = store, action) => {
  if (reducer[action.type]) return reducer[action.type]({ ...state }, action);
  return { ...state };
};
