import { Customer } from "@datenbanker/devcloud-client-lib";
import { getMy, setPending, setError } from "../actions/Timer";
export default {
  TIMER_GET_MY: async (store, next, action) => {
    next(setPending());
    try {
      const customer = new Customer();
      action.payload.timeClocks = await customer.task.timeClock.getAllMy();
      return next(action);
    } catch (err) {
      return next(setError());
    }
  },
  TIMER_STOP: async (store, next, action) => {
    next(setPending());
    try {
      const customer = new Customer();
      const newAction = getMy();
      newAction.payload.timeClocks = await customer.task.timeClock.getAllMy();
      return next(newAction);
    } catch (err) {
      return next(setError());
    }
  }
};
