const store = {
  pools: [],
  pending: true,
  error: null,
  receivedAt: null
};

export default (state = store, action) => {
  let newState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case "NAVIGATION_OPEN_ART":
    case "ART_POOLS_GET":
      newState.pending = false;
      newState.error = null;
      if (action.payload.receivedAt !== null) {
        newState.pools = payload.pools;
        newState.pending = false;
        newState.receivedAt = new Date();
      }
      return newState;
    case "ART_POOLS_PENDING":
      newState.pending = true;
      newState.error = null;
      return newState;
    case "ART_POOLS_ADD":
      newState.pending = true;
      newState.error = null;
      newState.receivedAt = null;
      return newState;
    case "ART_POOLS_DELETE":
      newState.pools = newState.pools.filter(pool => pool.id !== payload.id);
      return newState;
    default:
      return newState;
  }
};
