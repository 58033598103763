import { Art } from "@datenbanker/devcloud-client-lib";
import { pending } from "../actions/ArtPool";

export default {
  ART_POOL_GET: async (store, next, action) => {
    next(pending());
    try {
      const art = new Art();
      const pool = await art.pool.my(action.payload.id);
      action.payload.values = pool;
      return next(action);
    } catch (error) {
      console.log(error);
      return false;
    }
  }
};
