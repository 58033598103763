import { Customer } from "@datenbanker/devcloud-client-lib";

export default {
  PERSONS_LOAD: async (store, next, action) => {
    try {
      const customer = new Customer();
      const persons = await customer.person.getAll();
      action.payload.persons = persons;
      action.payload.pending = false;
      action.payload.errors = null;
      return next(action);
    } catch (error) {
      action.payload.persons = [];
      action.payload.pending = false;
      action.payload.errors = true;
      return next(action);
    }
  }
};
