import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { DevCloud } from "@datenbanker/devcloud-client-lib";
import { Theme, Authenticator } from "@datenbanker/react-devcloud-components";
import store from "./store";

DevCloud.init({
  apiToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiI1YjcyMjk1Zi0xNTg1LTQwYmEtYTRiNC04NjBkYmJhYjZkNGYiLCJzY29wZXMiOlsiYzIzNjAzZGMtZDVmNS00YmU4LWE4NzUtZDYzNTFhNzBmYTM5Il19.BnIyRjScYBeK-3OrkYNJtGP1ov2nwI-tk8B6thoYQ6Y",
  services: {
    authentication: {
      user: {
        pool: ["afaf03b3-7fed-4680-b00a-fd907c6ed696"] //0231a2b2-e877-4636-86d2-1e20b4d91838
      }
    },
    customer: {
      person: {
        pool: ["97378976-b084-4c31-9ee2-071f5cad0868"]
      },
      opportunity: {
        pool: ["46321b9c-7abd-4c7d-8e1e-3c2c96e20c96"]
      },
      task: {
        pool: ["66f81f66-2100-4368-93e6-06e339418d3d"]
      }
    }
  },
  groups: {
    "50a6153e-c3ad-4773-a622-f0793a2722af": "Basic",
    "1c98f678-3e1d-420a-aea4-5cfd52d44761": "Person",
    "d47c8455-919f-4926-bd83-8e6d5704865c": "User",
    "97e2577a-5c4f-464e-8bbd-a74abe0f1507": "Artist",
    "e1ebd13a-85ad-4070-aea7-73b9bf892c15": "Art",
    "ee3d787d-236c-40f1-9201-b46943781aeb": "Time Clock"
  },
  handler: {} // handler assigend in app component because they need redux
});
Theme.init();

ReactDOM.render(
  <Provider store={store}>
    <Authenticator>
      <App />
    </Authenticator>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
