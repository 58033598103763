import { Art } from "@datenbanker/devcloud-client-lib";

export default {
  ART_POOLS_GET: async (store, next, action) => {
    action.payload = {
      pools: [],
      receivedAt: null
    };

    try {
      const art = new Art();
      const pools = await art.pool.myAll();
      action.payload.pools = pools;
      action.payload.receivedAt = new Date();
      return next(action);
    } catch (error) {
      console.log(error);
      return false;
    }
  }
};
