import { connect } from "react-redux";

const data = store => {
  return {
    links: store.navigation.links,
    routes: store.navigation.routes
  };
};

const actions = dispatch => {
  return {};
};

export default component =>
  connect(
    data,
    actions
  )(component);
