export function get(id) {
  return {
    type: "PERSON_GET",
    payload: { id }
  };
}
export function reset(id) {
  return {
    type: "PERSON_RESET",
    payload: { id }
  };
}
export function getTimeClock(id) {
  return {
    type: "PERSON_TIMECLOCK_GET",
    payload: { id }
  };
}
export function update(fields, id) {
  return {
    type: "PERSON_UPDATE",
    payload: { fields, id }
  };
}
export function setMode(mode) {
  return {
    type: "PERSON_MODE_SET",
    payload: { mode }
  };
}
export function setReadOnly(readOnly) {
  return {
    type: "PERSON_READ_ONLY_SET",
    payload: { readOnly }
  };
}
