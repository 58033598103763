export function tokenChange(tokens) {
  return {
    type: "NAVIGATION_TOKEN_CHANGE",
    payload: { tokens }
  };
}
export function changeArtPool(id, name) {
  return {
    type: "NAVIGATION_CHANGE_ART_POOL",
    payload: { id, name }
  };
}
export function artOpen() {
  return {
    type: "NAVIGATION_OPEN_ART",
    payload: {}
  };
}
export function artClose() {
  return {
    type: "NAVIGATION_CLOSE_ART",
    payload: {}
  };
}
export function setArtPending(index) {
  return {
    type: "NAVIGATION_SET_ART_PENDING",
    payload: { index }
  };
}
