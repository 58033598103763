export function get(id) {
  return {
    type: "ART_POOL_GET",
    payload: { id }
  };
}
export function pending() {
  return {
    type: "ART_POOL_PENDING",
    payload: {}
  };
}
