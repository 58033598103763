import React, { Component } from "react";
import Container from "./resources/container/App";
import { Layouter } from "@datenbanker/react-devcloud-components";
import { DevCloud } from "@datenbanker/devcloud-client-lib";
import Storage from "@datenbanker/storage";
import Logo from "./resources/components/Logo";
const storage = new Storage();

class App extends Component {
  constructor(props) {
    super(props);
    DevCloud.registerHandler({
      userNotAllowed: () => {
        storage.delete("user:accessToken");
        storage.delete("user:idToken");
        storage.delete("user:refreshToken");
        window.location = "/user/forbidden";
      }
    });
  }
  render() {
    const { links, routes } = this.props;

    return (
      <Layouter
        layout={{
          name: "admin-extended",
          props: {
            logo: <Logo />,
            reduxEvents: {
              signOut: "SIGN_OUT"
            }
          }
        }}
        links={links}
        routes={routes}
      />
    );
  }
}

export default Container(App);
