import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import logo from "../assets/images/logo.svg";

const style = theme => ({
  wrapper: {
    padding: "8px 32px 0px 0px"
  },
  font: {
    fontFamily: "'Roboto Condensed', sans-serif",
    verticalAlign: "top",
    display: "inline-block",
    margin: "12px 0px 0px",
    color: "rgba(255, 255, 255, 1)"
  },
  "@media (max-width: 960px)": {
    wrapper: {
      padding: "8px 0px 0px"
    }
  }
});

class Logo extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <img src={logo} alt="webcollector logo" height="45" />
        {/* <span className={classes.font}>WEBCOLLECTOR</span> */}
      </div>
    );
  }
}

export default withStyles(style)(Logo);
