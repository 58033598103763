const store = {
  id: null,
  values: {
    title: "",
    firstName: "",
    lastName: "",
    gender: "",
    birthDate: "",
    placeOfBirth: "",
    salutation: "",
    company: "",
    position: "",
    potential: 0,
    quality: 0,
    description: "",
    email: "",
    phone: "",
    mobilePhone: "",
    businessPhone: "",
    website: "",
    facebook: "",
    instagram: "",
    street: "",
    zip: "",
    city: "",
    country: "",
    types: []
  },
  errors: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    types: ""
  },
  timeClock: { pending: true, unkownError: false, elements: [] },
  history: { pending: true, unkownError: false, elements: [] },
  types: [
    { label: "Kunde", value: "customer" },
    { label: "Künstler", value: "artist" },
    { label: "Mitarbeiter", value: "employee" },
    { label: "Verkäufer", value: "vendor" }
  ],
  mode: "contact",
  readOnly: true,
  unkownError: false,
  pending: true,
  typesPending: false,
  receivedAt: null
};

const reducer = {
  PERSON_GET: (state, action) => {
    const { payload } = action;
    state.values = payload.values;
    state.pending = payload.pending;
    state.errors = payload.errors;
    state.history = payload.history;
    state.unkownError = payload.unkownError;
    state.receivedAt = new Date();
    return state;
  },
  PERSON_RESET: (state, action) => {
    return { ...store };
  },
  PERSON_TIMECLOCK_GET: (state, action) => {
    const { payload } = action;
    state.timeClock = payload.timeClock;
    return state;
  },
  PERSON_UPDATE: (state, action) => {
    const { payload } = action;
    state.values = { ...state.values, ...payload.values };
    state.errors = { ...state.errors, ...payload.errors };
    return state;
  },
  PERSON_UPDATE_ERROR: (state, action) => {
    const { payload } = action;
    state.errors = { ...state.errors, ...payload.errors };
    return state;
  },
  PERSON_MODE_SET: (state, action) => {
    const { payload } = action;
    state.mode = payload.mode;
    return state;
  },
  PERSON_READ_ONLY_SET: (state, action) => {
    const { payload } = action;
    state.readOnly = payload.readOnly;
    return state;
  }
};
export default (state = store, action) => {
  if (reducer[action.type]) return reducer[action.type]({ ...state }, action);
  return { ...state };
};
