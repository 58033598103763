import navigation from "./Navigation";
import persons from "./Persons";
import person from "./Person";
import user from "./User";
import timer from "./Timer";
import artPools from "./ArtPools";
import artPool from "./ArtPool";

export default {
  ...navigation,
  ...persons,
  ...person,
  ...user,
  ...timer,
  ...artPools,
  ...artPool
};
