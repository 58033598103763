import { Authentication, Customer } from "@datenbanker/devcloud-client-lib";
import { pending, deleted } from "../actions/User";

export default {
  USER_GET: async (store, next, action) => {
    const { person, pool } = action.payload;
    action.payload.person = person;
    action.payload.values = {};
    action.payload.pending = false;
    action.payload.notFound = false;
    action.payload.unkownError = false;

    const oldStore = store.getState().user;
    const userGroups = oldStore.config.userGroups;

    const personService = new Customer().person;

    try {
      const user = await personService.getAllUsersForUserPool(person, pool);

      action.payload.id = user.id;
      action.payload.person = person;
      const values = {
        username: user.username || "",
        email: user.email || "",
        emailVerified: user.emailVerified || 0,
        phone: user.phone || "",
        phoneVerified: user.phoneVerified || 0,
        challenges: user.challenges || [],
        groups: user.groups.map(item => userGroups[item].value),
        disabled: Boolean(user.disabled)
      };
      action.payload.values = values;
    } catch (err) {
      if (err.code === "notFound") action.payload.notFound = true;
      else action.payload.unkownError = true;
    }

    action.payload.pending = false;

    return next(action);
  },
  USER_UPDATE: async (store, next, action) => {
    action.payload.values = {};
    action.payload.errors = {};
    action.payload.notFound = false;
    action.payload.unkownError = false;

    let set = {};
    Object.keys(action.payload.fields).forEach(field => {
      const value = action.payload.fields[field];
      switch (field) {
        case "disabled":
          set.disabled = 1;
          store.dispatch(pending("disable"));
          break;
        case "activate":
          set.disabled = 0;
          store.dispatch(pending("activate"));
          break;
        case "delete":
          set.deleted = 1;
          store.dispatch(pending("delete"));
          break;
        case "groups":
          set[field] = value;
          break;
        default:
          set[field] = value;
      }
    });
    const auth = new Authentication();
    try {
      await auth.user.update(set, action.payload.id);
      action.payload.values = set;
      if (action.payload.values.deleted) {
        store.dispatch(deleted());
        return false;
      }
      return next(action);
    } catch (err) {
      if (err.code === "validationError") {
        action.type = "USER_ERROR";
        const convertErrorCode = code => {
          switch (code) {
            case "alreadyExists":
              return "Existiert bereits.";
            case "notSet":
              return "Ist ein Pflichtfeld.";
            case "notString":
              return "Enthält ungültige Zeichen.";
            case "notInt":
              return "Ist keine Zahl.";
            case "notEmail":
              return "Enthält keine gültige Email-Adresse.";
            case "notPhone":
              return "Enthält keine gültige Telefonnummer.";
            default:
              return "Unbekannter Fehler. Code: " + code;
          }
        };
        const { errors } = err;
        let errorHelper = {};
        errors.forEach(error => {
          errorHelper[error.key] = convertErrorCode(error.error);
        });

        action.payload.errors = errorHelper;
      } else if (err.code === "notFound") {
        action.payload.notFound = true;
      } else {
        action.payload.unknownError = true;
      }

      return next(action);
    }
  }
};
