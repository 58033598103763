export function getMy() {
  return {
    type: "TIMER_GET_MY",
    payload: {}
  };
}
export function setPending() {
  return {
    type: "TIMER_SET_PENDING",
    payload: {}
  };
}
export function setError() {
  return {
    type: "TIMER_SET_ERROR",
    payload: {}
  };
}
