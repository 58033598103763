import { createStore, combineReducers, applyMiddleware } from "redux";
import reducer from "./reducer";
import middleware from "./middleware";

export default createStore(
  combineReducers(reducer),
  {},
  applyMiddleware(store => next => async action => {
    if (middleware[action.type])
      return middleware[action.type](store, next, action);
    return next(action);
  })
);
